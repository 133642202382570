import { ChangeEvent } from "react";

import { toDecimal } from ".";

export const normalizedNumberStringOnChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const value = event.target.value.replace("$", "");
  const checkingValue = value === "-" ? -1 : Number(value);
  if (!isFinite(checkingValue) || isNaN(checkingValue)) {
    return;
  }
  return value;
};

export const normalizedNumberStringOnBlur = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  allowNegativeNumbers: boolean = false,
  maxValueLimit?: number | string,
  minValueLimit?: number | string,
) => {
  const valueString = event.target.value.replace("$", "");
  if (valueString === "") return valueString;

  let value = parseFloat(valueString);
  if (!isFinite(value) || isNaN(value)) value = 0;

  let resultValue = toDecimal(value).toDP(8);
  const decimalPlaces = resultValue.dp();

  if (minValueLimit && resultValue.lt(minValueLimit))
    resultValue = toDecimal(minValueLimit);
  if (maxValueLimit && resultValue.gt(maxValueLimit))
    resultValue = toDecimal(maxValueLimit);

  return allowNegativeNumbers
    ? resultValue.toFixed(decimalPlaces)
    : resultValue.abs().toFixed(decimalPlaces);
};
