import React from "react";
// import replaceall from "replaceall";
import replaceall from "string-replace-all";
import matchAll from "match-all";

import { emojiList } from "components/Chat/components/Write/data";
import { isDevelop } from "./isDevelopment";

export const cdnEmojiUrl = "https://cdn.pasino.com/emoji";

const findSmileReg =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

const emojiClassName = "emojione";

export const smileToImg = (smile: string) => {
  if (smile.length >= 4) {
    return `<img class="${emojiClassName}" alt="${smile}" title=":${smile}:" src="${cdnEmojiUrl}/${smile}.png">`;
  } else {
    const smileData = emojiList.find((value) => value.smile === smile);

    if (smileData) {
      return `<img class="${emojiClassName}" alt="${smileData.smile}" src="${cdnEmojiUrl}/${smileData.base}.png" >`;
    } else {
    }
    return smile;
  }
};

export const reduceSmileString = (text: string, asJSX = false) => {
  let returnTextValue = text;
  const matchedSmile: string[] = matchAll(text, findSmileReg).toArray();
  const smiles = new Set<string>();

  matchedSmile.forEach((emoji, i) => {
    if (isDevelop() && typeof emoji !== "string") {
      console.log("matchedSmile error!");
      console.error("matchedSmile error!");
    }

    smiles.add(emoji);
  });

  smiles.forEach((emoji) => {
    const convertedEmoji = smileToImg(emoji);

    returnTextValue = replaceall(returnTextValue, emoji, convertedEmoji);
  });

  return returnTextValue;
};

let createdJSXEmoji: Map<string, JSX.Element> = new Map();
// let createdJSXEmoji: Record<string, JSX.Element> = {};

export const smileToJSXImg = (smile: string) => {
  let code = smile;
  let alt = smile;
  let title = smile;

  if (smile.length < 4) {
    const smileData = emojiList.find((value) => value.smile === smile);
    if (smileData) {
      alt = smileData.smile;
      code = smileData.base;
    }
  }
  const createdJSXEmojiEl = createdJSXEmoji.get(code);
  if (createdJSXEmojiEl) {
    return createdJSXEmojiEl;
  }
  let src = cdnEmojiUrl + "/" + code + ".png";
  const el = <img className="emojione" alt={alt} title={title} src={src} />;
  createdJSXEmoji.set(code, el);
  return el;
};
export const replaceEmojiWithImage = (text: string) => {
  const matchedSmile: string[] = matchAll(text, findSmileReg).toArray();
  const parts = text.split(findSmileReg);

  const result = parts.map((part, index) => {
    const isSmile = part && matchedSmile.includes(part);
    if (isSmile) {
      return smileToJSXImg(part);
    } else {
      return part;
    }
  });
  return result;
};
